import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

const InverterCapacity = () => {
    const {energy, power} = useParams()
    const navigate = useNavigate()
    const [inverter1, setInverter1] = useState(0)
    const [inverter2, setInverter2] = useState(0)
    console.log('Battery capacity energy: ',energy)
    
    const calculateInverterCapacity = (load, constant) => {
        let result = (load * constant) / 1000
        return result
    }
    
    
    useEffect(() => {
        let invert1 = calculateInverterCapacity(power, 2)
        let invert2 = calculateInverterCapacity(power, 4)
        setInverter1(invert1)
        setInverter2(invert2)
    }, [power])


    const handleBtnClick = () => {
        console.log('Successfully In')
        navigate(`/panel-capacity/${energy}`)
    }

  return (
    <div className="px-10">
        <div className="wrapper max-w-[500px] mx-auto mt-3">
            <div className='text-4xl font-[500] md:font-[600] text-[#d4b742] text-center mt-10 md:mt-7'>Inverter Capacity</div>
            <div className="storage- text-center mt-16">
                <div className="text-gray-200 text-base font-[500] leading-6">The minimum required Inverter capacity for a <span className="font-[600] md:font-[800] px-1">{energy} (Wh)</span> system is:</div>
                <div className="text-gray-100 text-4xl font-[900] md:font-[800] mt-10 lg:mt-5">{inverter1} KVA</div>
                <div className="text-[#d4b742] font-[700] py-5">Or</div>
                <div className="text-gray-100 text-4xl font-[900] md:font-[800]">{inverter2} KVA</div>
                <div className="text-gray-300 mt-2">In case of surge</div>
            </div>
            <div className='proceed-battery-capacity flex items-center justify-center mt-24 md:mt-20 pb-14'>
                <button onClick={() => handleBtnClick()} className="bg-[#c2a42b] text-sm text-gray-900 b-green-600 mt-3 rounded shadow shadow-gray-600 font-[700] px-3 py-2" type="submit">See Panel Capacity</button>
            </div>
        </div>
    </div>
  )
}

export default InverterCapacity