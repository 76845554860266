import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'


const BatteryCapacity = () => {
    const {energy, power} = useParams()
    const navigate = useNavigate()
    console.log('Battery capacity energy: ',energy)
    const handleBtnClick = () => {
        navigate(`/inverter-capacity/${energy}/${power}`)
    }

  return (
    <div className="px-10">
        <div className="wrapper max-w-[500px] mx-auto mt-3">
            <div className='text-4xl font-[500] md:font-[600] text-[#d4b742] text-center mt-10 md:mt-7'>Battery Capacity</div>
            <div className="storage- text-center mt-16">
                <div className="text-gray-200 text-base font-[500] leading-6">The minimum required Backup storage capacity for a <span className="font-[600] md:font-[800] px-1">{energy} (Wh)</span> system is:</div>
                <div className="text-gray-100 text-4xl font-[900] md:font-[800] mt-10 lg:mt-5">{energy} Ah</div>
            </div>
            <div className='proceed-battery-capacity flex items-center justify-center mt-24 md:mt-20 mb-10'>
                <button onClick={() => handleBtnClick()} className="bg-[#c2a42b] text-sm text-gray-900 b-green-600 mt-3 rounded shadow shadow-gray-600 font-[700] px-3 py-2" type="button">See Inverter Capacity</button>
            </div>
        </div>
    </div>
  )
}

export default BatteryCapacity
