import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import APPLIANCES from '../data/appliances'

const Home = () => {
    const [power, setPower] = useState([[], [], []]);
    const [numbers, setNumbers] = useState([[], [], []])
    const [dailyUsages, setDailyUsages] = useState([[], [], []])
    const [energy, setEnergy] = useState([[], [], []])
    const [appliances, setAppliances] = useState([[], [], []])
    const [totalEnergy, setTotalEnergy] = useState(0)
    // const [totalPower, setTotalPower] = useState(0)
    const navigate = useNavigate()


    function calculateTotalEnergy(en) {
        let total = 0

        en.forEach((val) => {
            total += Number(val)
            setTotalEnergy(total)
        })
    }

    const calculateTotalPower = (data) => {
        let totalPow = 0
        for (let i = 0; i < data.length; i++) {
            // console.log(val)
                totalPow = totalPow + Number(data[i])
        } 

        return totalPow
    }    

    useEffect(() => {
        function calculateViewEnergy() {
            // ENERGY = POWER * TIME
            let en = [...energy]
            for (var i=0; i<power.length; i++) {
                if (Number(power[i]) === 0 || Number(dailyUsages[i]) === 0 ||  Number(numbers[i]) === 0
                    || power[i] === [] || dailyUsages[i] === [] || numbers[i] === []) {  
                    en[i] = 0
                    setEnergy(en)
                    calculateTotalEnergy(en)
                    // console.log('En if 1', en)
                    // console.log("Energy 1: ", energy) 
                } 
                else {
                    en[i] = Number(power[i]) * Number(dailyUsages[i]) * Number(numbers[i]) 
                    setEnergy(en)
                    calculateTotalEnergy(en)
                    // console.log('En if 2', en)
                    // console.log("Energy 2: ", energy)
                }
            }
            // console.log('EN 1', en)
            
            // console.log('EN: ', en)
            // console.log("Energy: ", energy)
        }

        calculateViewEnergy()
    }, [power, dailyUsages, numbers, energy])

    const handleApplianceChange = (e, i) => {
        let data = [...appliances] 
        data[i] = e.target.value
        setAppliances(data)
    }
    
    function handlePowerChange(e, i) {
        const data = [...power]
        data[i] = e.target.value
        setPower(data)
    }

    const remove = (array, idx) => {
        let newArray = []
        let i = 0
        while (i < array.length) {
            if (i !== idx) {
                newArray.push(array[i])
            }
            i+=1
        }
        return newArray
    }

    const handleNumberChange = (e, i) => {
        const data = [...numbers]
        data[i] = e.target.value
        setNumbers(data)
    }

    const handleDailyUsageChange = (e, i) => {
        const data = [...dailyUsages]
        data[i] = e.target.value
        setDailyUsages(data)
    }

    // const handleEnergyChange = (e, i) => {
    //     const data = [...energy]
    //     data[i] = e.target.value
    //     setEnergy(data)
    // }

    const handleDelete = (i) => {
        console.log(i)
        let data1 = [...power]
        let data2 = [...numbers]
        let data3 = [...dailyUsages]
        let data4 = [...energy]
        let data5 = [...appliances]

        data1 = remove(data1, i)
        data2 = remove(data2, i)
        data3 = remove(data3, i)
        data4 = remove(data4, i)
        data5 = remove(data5, i)

        setPower(data1)
        setNumbers(data2)
        setDailyUsages(data3)
        setEnergy(data4)
        setAppliances(data5)
    }

    function handleAddClick() {
        setPower([...power, []])
        setNumbers([...numbers, []])
        setDailyUsages([...dailyUsages, []])
        setEnergy([...energy, []])
        setAppliances([...appliances]) 
    }


    const handleBtnClick = () => {
        // calculateEnergy()
        let pow = calculateTotalPower(power)
        navigate(`/battery-capacity/${totalEnergy}/${pow}`)
    }

    return (
        <div className="">
            <div className="flex justify-center mt-7 px-3">
                <div className="fields">
                    <div className="labels flex justify-between items-center gap-3 text-xs text-gray-300 border-b border-t border-gray-400 py-3 mb-5">
                        <div className="max-w-[8rem] sm:min-w-[8rem]">
                            <label className="font-[400] w-full leading-tighter" htmlFor="label-1">Appliance:</label>
                        </div>
                        <div className="max-w-[3.5rem] sm:min-w-[3.5rem]">
                            <label className="font-[400] w-full leading-tighter" htmlFor="label-1">Power Rating <span className="font-[600]">(W)</span>:</label>
                        </div >
                        <div className="max-w-[3.5rem] sm:min-w-[3.5rem]">
                            <label className="font-[400]  w-full leading-tighter" htmlFor="label-1">No. of item:</label>
                        </div>
                        <div className="max-w-[3.5rem] sm:min-w-[3.5rem]">
                            <label className="font-[400] w-full leading-tighter" htmlFor="label-1">Average daily usage <span className="font-[600]">(Hr)</span>:</label>
                        </div>
                        <div className="max-w-[5rem] sm:min-w-[5rem]">
                            <label className="font-[400] leading-tighter" htmlFor="label-1">Energy usages <span className="font-[600]">(Wh)</span>:</label>
                        </div>
                    </div>
                    <form onSubmit={() => handleBtnClick()} action=".">
                        {   power.map((item, i) => {
                                return (
                                <div key={i} className="input-group flex gap-3 mb-3 items-center">
                                    <div className="max-w-[8rem]">
                                        <select onChange={e => handleApplianceChange(e, i)} value={appliances[i]} className="h-8 rounded-sm w-full text-xs bg-[#252526] border border-[#726019] shadow-gray-600 outline-none text-[#d4b742]">
                                            {
                                                APPLIANCES.map((item, j) => {
                                                    // if (appliances[i] == item)
                                                    return <option key={j} className='text-xs' value={item}>{item}</option>
                                                })
                                            }
                                            
                                        </select>
                                    </div>
                                    <div className="max-w-[5rem]">
                                        <input name={power[i]} value={power[i]} required placeholder="250" onChange={e => handlePowerChange(e, i)} className="block w-full text-sm border border-[#726019] outline-none rounded-sm h-8 px-1 text-center bg-[#3E3E42] text-gray-300 font-[500]" id="label-1" type="number" />
                                    </div>
                                    <div className="max-w-[3.5rem]">
                                        <input name={numbers[i]} value={numbers[i]} required placeholder="1" onChange={e => handleNumberChange(e, i)}  className="block w-full text-sm border border-[#726019] outline-none rounded-sm h-8 px-1 text-center bg-[#3E3E42] text-gray-300 font-[500]" id="label-1" type="number" />
                                    </div>
                                    <div className="max-w-[3.5rem]">
                                        <input name={dailyUsages[i]} value={dailyUsages[i]} required placeholder="24" onChange={e => handleDailyUsageChange(e, i)}  className="block w-full text-sm border border-[#726019] hover:outline-none rounded-sm h-8 px-1 text-center bg-[#3E3E42] text-gray-300 font-[500]" id="label-1" type="number" />
                                    </div>
                                    <div className="max-w-[5rem]">
                                        <input name={energy[i]} value={energy[i]} placeholder="6000" className="block w-full text-sm rounded-sm h-8 px-1 outline-none disabled text-center bg-[#3E3E42] text-gray-300 font-[500]" id="label-1" type="number" disabled />
                                    </div>
                                    <button type="button" onClick={() => handleDelete(i)} className='delete font-[600] text-gray-200 text-xs hover:cursor-pointer'>
                                        X
                                    </button>
                                </div>
                            )})
                                
                        }
                        {/* <div className="text-gray-300 mb-2 text-sm underline mt-5 font-[400] text-right">Add More Appliances:</div> */}
                        <div className="mb-5 flex justify-end">
                            <button type="button" onClick={() => handleAddClick()} className="bg-[#c2a42b] font-[700] px-2 py-2 text-gray-800 rounded text-sm shadow shadow-gray-600 mr-5">Add</button>
                        </div>
                        <div className="border-t mt-5 border-gray-600"></div>
                        <div className='flex justify-between items-center gap-5 mb-10#
                        '>
                            <button className="bg-[#c2a42b] text-sm text-gray-900 b-green-600 mt-3 rounded shadow shadow-gray-600 font-[700] px-3 py-2" type="submit">See Installation Capacity</button>
                            <div className='text-gray-200 font-[500] mt-3'>Total Energy Consumption: <span className="text-lg">{totalEnergy} WH</span></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default Home;
