import React from 'react';
import Logo from '../images/Logo.jpeg';

const header = () => (
    <div className="text-center pt-5 sm:pt-7 pb-5">
        <div className="flex justify-center mb-2">
          <img className="w-[3.4rem] h-[3.4rem] md:w-16 md:h-16 rounded-[50%]" src={Logo} alt="Manval Logo" />
        </div>
        <div className="text-lg md:text-xl text-[#94D2C8] font-bold">Manval Solar Technologies</div>
        <div className="text-sm text-gray-300">Installation Capacity Calculation App</div>
    </div>
  );

export default header;
