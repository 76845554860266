import React from 'react';
import { Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import BatteryCapacity from './pages/BatteryCapacity';
import InverterCapacity from './pages/InverterCapacity';
import PanelCapacity from './pages/PanelCapacity';


const App = () => {
  return (
    <div className="bg-[#1b1509]">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/battery-capacity/:energy/:power" element={<BatteryCapacity />} />
          <Route path="/inverter-capacity/:energy/:power" element={<InverterCapacity />} />
          <Route path="/panel-capacity/:energy" element={<PanelCapacity />} />
        </Routes>
    </div>
)}

export default App;

