const APPLIANCES =[
    "Air Conditioner",
    "Blender",
    "Blow Drier",
    "Bulb",
    "CD/VCR/VCD/DVD Player",
    "ceiling Fan",
    "Cloths Dryer",
    "Coffee Maker",
    "Desktop Computer",
    "Electric Blanket",
    "Fridge",
    "Freezer/Fridge",
    "Hot Plate",
    "Laptop Computer",
    "Lawn Mower",
    "Lights Compact Fluorescent",
    "Lights LED",
    "Lights Incandescent",
    "Microwave",
    "Pressing Iron",
    "Printer",
    "Satellite Dish",
    "Sewing Machine",
    "Shaver",
    "Table Fan",
    "Television",
    "Toaster",
    "Typewriter (Electric)",
    "Vacuum Cleaner",
    "Washing Machine",
    "Water Heater",
    "Water Pump",
    "DC Motor"
]

export default APPLIANCES